<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <form>
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Smart Money System Url</mat-label>
              <input
                matInput
                [(ngModel)]="config.smartMoneySystemUrl"
                name="smartMoneySystemUrl"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Smart Money System User</mat-label>
              <input
                matInput
                [(ngModel)]="config.smartMoneySystemUser"
                name="smartMoneySystemUser"
              />
            </mat-form-field>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Smart Money System Password</mat-label>
              <input
                type="password"
                matInput
                [(ngModel)]="config.smartMoneySystemPassword"
                name="smartMoneySystemPassword"
              />
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 align-right">
            <button
              class="form-button button-form-ptt"
              (click)="saveData()"
              mat-raised-button
              color="accent"
            >
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
