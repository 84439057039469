import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { SubcompaniesListModel } from 'src/app/shared/models/SubcompaniesListModel.model';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { SubcompanyModel } from 'src/app/shared/models/SubcompanyModel.model';
import { CreateSubcompanyModel } from 'src/app/shared/models/CreateSubcompanyModel.model';

@Injectable({
  providedIn: 'root'
})
export class SubcompanyService {

  private userToken: string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
  }
  public GetAllColumnNames(): Observable<string[]> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/Subcompany/GetAllColumnNames', header).pipe();
  }

  public GetAllSubcompanies(filterParameters: FiltersModel): Observable<SubcompaniesListModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<SubcompaniesListModel>(environment.apiUrl + 'api/v1/Subcompany/GetAllSubcompanies', filterParameters, header).pipe();
  }

  public DeleteListOfSubcompanies(listOfDocuments: any[]): Observable<any> {
    const $this = this;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
        .set('Authorization', 'Bearer ' + $this.userToken),
      body: listOfDocuments,
    };
    return $this.http.delete<any>(environment.apiUrl + 'api/v1/Subcompany', options).pipe();
  }

  public GetSubcompanyById(id: number): Observable<SubcompanyModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.get<SubcompanyModel>(environment.apiUrl + 'api/v1/Subcompany/' + id, header).pipe();
  }

  public SaveSubcompany(argSubcompanyData: CreateSubcompanyModel): Observable<string> {

    const $this = this;

    return $this.http.post<string>(environment.apiUrl + 'api/v1/Subcompany', argSubcompanyData).pipe();

  }
}
