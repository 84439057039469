import { PmsIntegratorService } from 'src/app/services/pms-integrator-service/pms-integrator.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { PmsSettingService } from 'src/app/services/pms-setting-service/pms-setting.service';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { PublicPMSSettingModel } from 'src/app/shared/models/public-pmssetting-model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent {
  displayedColumns: string[] = ['select', 'id', 'pmsType', 'syncHour'];
  dataSource: MatTableDataSource<PublicPMSSettingModel>;
  editMode: boolean = false;
  numSelected: number;
  columnList: string[];
  hasPMSSettings: boolean;
  filters: FiltersModel = {
    ColumnsToSearch: [],
    CurrentPage: 0,
    PageSize: 10,
    OrderColumn: null,
    OrderType: null,
    SearchText: null,
    FilterTags: []
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private pmsSettingService: PmsSettingService,
    private loadingService: LoadingService, private editDataServiceService: EditDataServiceService, private pmsIntegratorService: PmsIntegratorService,
    private cdRef: ChangeDetectorRef, private snackBar: MatSnackBar) {

    const $this = this;

    $this.loadingService.toggle("");

    $this.pmsSettingService.GetAllColumnNames().subscribe(resp => {
      $this.columnList = resp;
    });

    $this.refreshList(true);
  }

  selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const $this = this;
    if ($this.dataSource == null || $this.dataSource == undefined) {
      return false;
    } else {
      $this.numSelected = $this.selection.selected.length;
      const numRows = $this.dataSource.data.length;
      return $this.numSelected === numRows;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngAfterViewChecked() {
    const $this = this;
    $this.cdRef.detectChanges();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addNew() {
    const $this = this;
    $this.router.navigate(["backoffice/integrations/integration-form"]);
  }

  delete() {
    const $this = this;
    const selectedIds = $this.selection.selected.map(({ id }) => id);
    $this.loadingService.toggle("");
    $this.pmsSettingService.DeleteListOfPMSSetting(selectedIds).subscribe(() => {
      $this.refreshList();
    });
  }

  edit() {
    const $this = this;
    $this.editDataServiceService.pmsSettingIdToEdit = $this.selection.selected[0]['id'];
    $this.router.navigate(["backoffice/integrations/integration-form"]);
  }

  search() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.refreshList();
  }

  refreshList(updateHasPmsSettings: boolean = false) {
    const $this = this;

    if ($this.paginator != null) {
      $this.filters.PageSize = $this.paginator.pageSize;
      $this.filters.CurrentPage = $this.paginator.pageIndex;
    }

    $this.selection = new SelectionModel<any>(true, []);
    $this.numSelected = 0;
    $this.pmsSettingService.GetAllPMSSettings($this.filters).subscribe(resp => {
      if (resp != null) {
        $this.dataSource = new MatTableDataSource(resp.pmsSettings);
        if (updateHasPmsSettings) {
          $this.hasPMSSettings = resp.pmsSettings.length > 0;
        }
        $this.dataSource.paginator = $this.paginator;
        $this.dataSource.sort = $this.sort;
        $this.loadingService.toggle("");
      }
    });
  }

  initializePMSSync() {
    const $this = this;
    $this.loadingService.toggle("");

    $this.pmsIntegratorService.InitializePMSSync().subscribe(resp => {

      let message = resp ? "Sincronização Iniciada com Sucesso..." : "Falha na Iniciação da Sincronização...";

      $this._renderSnackbar(message);

      $this.loadingService.toggle("");

    });
  }

  forceSyncManual() {
    const $this = this;
    $this.loadingService.toggle("");

    $this.pmsIntegratorService.ForceSyncManual().subscribe(resp => {

      let message = resp ? "Sincronização Forçada com Sucesso..." : "Falha no Forcing da Sincronização...";

      $this._renderSnackbar(message);

      $this.loadingService.toggle("");

    });
  }

  _renderSnackbar(message: string) {
    const $this = this;
    $this.snackBar.open(message, 'Fechar', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 5 * 1000,
      panelClass: ["custom-snackbar"]
    });
  }
}
