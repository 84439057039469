<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    
    <div class="row">
      <div class="col-xl-12 col-md-12 col-sm-12">
        <div class="search-buttons-col">
          <button *ngIf="numSelected != 1" (click)="addNew()" [disabled]="numSelected >= 1" mat-flat-button
            color="primary">
            <mat-icon>add</mat-icon>
          </button>
          <button *ngIf="numSelected == 1" mat-flat-button color="primary" (click)="edit()">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </div>


    <div class="mat-elevation-z8 search-container">

      <div class="table-container">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="select">
            <th mat-header-cell [ngClass]="'custom-w-75'" *matHeaderCellDef>
              <mat-checkbox (keyup)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell [ngClass]="'custom-w-75'" *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell [ngClass]="'custom-w-75'" *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell [ngClass]="'custom-w-75'" *matCellDef="let row"> {{row.id}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="publishDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Data de Publicação </th>
            <td mat-cell *matCellDef="let row"> {{row.publishDate}} </td>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="language">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Idioma </th>
            <td mat-cell *matCellDef="let row"> {{row.language.description }} </td>
          </ng-container>

          <!-- Color Column -->
          <ng-container matColumnDef="lastVersionNy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Última Versão? </th>
            <td mat-cell *matCellDef="let row">
              <mat-slide-toggle [checked]="row.lastVersionNy" disabled="true">
              </mat-slide-toggle>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">No data...</td>
          </tr>
        </table>
      </div>

      <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
  </mat-card>
</div>
