<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <div style="padding: 10px">
        <div class="row row-form-container">
          <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Dados Gerais">
              <div class="container container-form-general-data">
                <form>
                  <div class="row">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                      <mat-form-field class="input-full-width">
                        <mat-label>Nome</mat-label>
                        <input
                          [readonly]="isEdit"
                          matInput
                          [(ngModel)]="roomType.name"
                          (keyup)="checkFields()"
                          name="name"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                      <mat-form-field class="input-full-width">
                        <mat-label>Descrição</mat-label>
                        <textarea
                          matInput
                          rows="6"
                          [(ngModel)]="roomType.description"
                          (keyup)="checkFields()"
                          name="description"
                        ></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-md-4 col-sm-12">
                      <mat-form-field class="input-full-width">
                        <mat-label>Subempresa</mat-label>
                        <mat-select
                          [(ngModel)]="roomType.subcompanyId"
                          (selectionChange)="checkFields()"
                          name="companyId"
                        >
                          <mat-option
                            *ngFor="let subcompany of subcompanies"
                            [value]="subcompany.id"
                          >
                            {{ subcompany.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-md-4 col-sm-12">
                      <mat-form-field class="input-full-width">
                        <mat-label>Local</mat-label>
                        <mat-select
                          [(ngModel)]="roomType.localId"
                          (selectionChange)="checkFields()"
                          name="localId"
                        >
                          <mat-option
                            *ngFor="let local of locals"
                            [value]="local.id"
                          >
                            {{ local.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-md-4 col-sm-12">
                      <mat-form-field class="input-full-width">
                        <mat-label>Estado</mat-label>
                        <mat-select
                          [(ngModel)]="roomType.state"
                          (selectionChange)="checkFields()"
                          name="cardEncoderType"
                        >
                          <mat-option
                            *ngFor="
                              let documentStateType of documentStateTypes
                                | enumToArray
                            "
                            [value]="documentStateType.index"
                          >
                            {{ documentStateType.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                      <mat-toolbar>
                        <mat-icon>image</mat-icon>
                        <span class="language-description"
                          >Fotos 
                          <small
                            >(Limite por Ficheiro
                            <b>2MB</b>)</small
                          ></span
                        >
                      </mat-toolbar>
                      <mat-divider></mat-divider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                      <div class="room-photos-container">
                        <input
                          type="file"
                          #roomPhotosInput
                          multiple
                          accept="image/png, image/jpeg, image/webp"
                          (change)="onFileSelected($event)"
                          hidden
                        />
                        <ul class="room-photos-list">
                          <li>
                            <button
                              (click)="triggerFileInput(roomPhotosInput)"
                              mat-raised-button
                              class="add-photo"
                            >
                              <mat-icon>add_a_photo</mat-icon>
                            </button>
                          </li>
                          <li
                            *ngFor="
                              let photo of roomType.roomPhotos;
                              let i = index
                            "
                          >
                            <img
                              [src]="photo.fileToDisplay"
                              alt="{{ photo.fileName }}"
                              class="room-photos"
                            />
                            <mat-icon
                              (click)="removePhoto(i)"
                              class="remove-img-btn"
                              >close</mat-icon
                            >
                            <div (click)="openPhotoPreview(i)" class="overlay-room-photo">
                              <mat-icon>preview</mat-icon>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </mat-tab>
            <mat-tab label="Traduções">
              <div class="container container-form-general-data">
                <form>
                  <ng-container
                    *ngFor="
                      let language of languages;
                      trackBy: trackByLanguageId
                    "
                  >
                    <div class="row">
                      <div class="col-xl-12 col-md-12 col-sm-12">
                        <mat-toolbar>
                          <mat-icon>translate</mat-icon>
                          <span class="language-description">{{
                            language.description
                          }}</span>
                        </mat-toolbar>
                        <mat-divider></mat-divider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-12 col-md-12 col-sm-12">
                        <mat-form-field class="input-full-width">
                          <mat-label>Nome</mat-label>
                          <input
                            matInput
                            [ngModel]="
                              getTranslation(language.id, 'RoomType_Name')
                            "
                            (ngModelChange)="
                              setTranslation(
                                language.id,
                                'RoomType_Name',
                                $event
                              )
                            "
                            name="RoomType_Name_{{ language.id }}"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-12 col-md-12 col-sm-12">
                        <mat-form-field class="input-full-width">
                          <mat-label>Descrição</mat-label>
                          <textarea
                            matInput
                            rows="6"
                            [ngModel]="
                              getTranslation(
                                language.id,
                                'RoomType_Description'
                              )
                            "
                            (ngModelChange)="
                              setTranslation(
                                language.id,
                                'RoomType_Description',
                                $event
                              )
                            "
                            name="RoomType_Description_{{ language.id }}"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                  </ng-container>
                </form>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button
              class="form-button button-form-ptt"
              (click)="goBack()"
              mat-raised-button
              color="accent"
            >
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div class="col-6 align-right">
            <button
              class="form-button button-form-ptt"
              (click)="saveData()"
              [disabled]="!enableSave"
              mat-raised-button
              color="accent"
            >
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
