<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <div style="padding: 10px;">
        <div class="row row-form-container">
          <form>
            <div class="row">
              <div class="col-xl-8 col-md-8 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nome</mat-label>
                  <input matInput [(ngModel)]="subcompany.name" (keyup)="checkFields()" name="name">
                </mat-form-field>
              </div>

              <div class="col-xl-4 col-md-4 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Contacto</mat-label>
                  <input matInput [(ngModel)]="subcompany.contact" (keyup)="checkFields()" type="tel" name="contact">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Email</mat-label>
                  <input matInput [(ngModel)]="subcompany.email" (keyup)="checkFields()" type="email" name="email">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Password</mat-label>
                  <input matInput type="password" [(ngModel)]="subcompany.passwordEmail" (keyup)="checkFields()"
                    name="passwordEmail">
                </mat-form-field>
              </div>
              <div class="col-xl-2 col-md-2 col-sm-12">
                <div class="input-full-width mat-form-field-flex slide-container">
                  <mat-slide-toggle [(ngModel)]="subcompany.requiresSll" (change)="checkFields()"
                    name="hasSEFIntegration">
                    Usa SSL?
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="col-xl-4 col-md-4 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Empresa</mat-label>
                  <mat-select [(ngModel)]="subcompany.companyId" (selectionChange)="checkFields()" name="companyId">
                    <mat-option *ngFor="let company of companies" [value]="subcompany.companyId">
                      {{ company.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Incoming Email Server</mat-label>
                  <input matInput [(ngModel)]="subcompany.incomingEmailServer" (keyup)="checkFields()"
                    name="incomingEmailServer">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Incoming Email Port</mat-label>
                  <input matInput [(ngModel)]="subcompany.incomingEmailPort" (keyup)="checkFields()"
                    name="incomingEmailPort">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Outgoing Email Server</mat-label>
                  <input matInput [(ngModel)]="subcompany.outgoingEmailServer" (keyup)="checkFields()"
                    name="outgoingEmailServer">
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Outgoing Email Port</mat-label>
                  <input matInput [(ngModel)]="subcompany.outgoingEmailPort" (keyup)="checkFields()"
                    name="outgoingEmailPort">
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button class="form-button button-form-ptt" (click)="goBack()" mat-raised-button color="accent">
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div class="col-6 align-right">
            <button class="form-button button-form-ptt" (click)="saveData()" [disabled]="!enableSave" mat-raised-button
              color="accent">
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
