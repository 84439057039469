<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <form>
        <div class="row">
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Descrição (*)</mat-label>
              <input matInput [(ngModel)]="language.description" (keyup)="checkFields()" name="description">
            </mat-form-field>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>ISO (*)</mat-label>
              <input matInput [(ngModel)]="language.iso" (keyup)="checkFields()" name="iso">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="kiosk-logo-container input-full-width">

              <input style="display: none;" type="file" accept="image/x-png,image/jpeg" (change)="uploadFile($event.target.files);checkFields()"
                name="logo" #fileInput>
              <div class="kiosk-logo-column">
                <mat-icon aria-hidden="false" *ngIf="language.languageImage == null" class="logo-container-icon" aria-label="Image">image
                </mat-icon>
                <img [src]="logoDisplay" *ngIf="language.languageImage != null" class="logo-image">
              </div>

              <div class="kiosk-logo-column">
                <div class="document-btn-container-col">
                  <div class="document-btn-container-wrapper">
                    <button class="form-button button-form-ptt" (click)="fileInput.click()" mat-raised-button
                      color="btn-custom">
                      <mat-icon aria-hidden="false">file_upload</mat-icon>
                      UPLOAD
                    </button>
                  </div>
                </div>
                <div class="document-btn-container-col">
                  <div class="document-btn-container-wrapper">
                    <button class="form-button button-form-ptt" (click)="clearLogo()" mat-raised-button color="btn-custom">
                      <mat-icon aria-hidden="false">
                        delete_forever
                      </mat-icon>
                      REMOVER
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button class="form-button button-form-ptt" (click)="goBack()" mat-raised-button color="accent">
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div class="col-6 align-right">
            <button class="form-button button-form-ptt" (click)="saveData()" [disabled]="!enableSave" mat-raised-button color="accent">
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
