import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { BoardTypeListModel } from 'src/app/shared/models/board-type-list-model';
import { BoardTypeModel } from 'src/app/shared/models/board-type-model';

@Injectable({
  providedIn: 'root'
})
export class BoardTypesService {

  private userToken: string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
  }

  public GetAllColumnNames(): Observable<string[]> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/BoardType/GetAllColumnNames', header).pipe();
  }

  public GetBoardTypeById(argId: number): Observable<BoardTypeModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.get<BoardTypeModel>(environment.apiUrl + 'api/v1/BoardType/' + argId, header).pipe();
  }

  public GetAllBoardTypes(filterParameters: FiltersModel): Observable<BoardTypeListModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<BoardTypeListModel>(environment.apiUrl + 'api/v1/BoardType/GetBoardTypes', filterParameters, header).pipe();
  }

  public SaveBoardType(argDataToSave: BoardTypeModel): Observable<BoardTypeModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<BoardTypeModel>(environment.apiUrl + 'api/v1/BoardType', argDataToSave, header).pipe();
  }

  public DeleteLstOfBoardTypes(listOfDocuments: any[]): Observable<any> {
    const $this = this;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
        .set('Authorization', 'Bearer ' + $this.userToken),
      body: listOfDocuments,
    };
    return $this.http.delete<any>(environment.apiUrl + 'api/v1/BoardType', options).pipe();
  }
}
