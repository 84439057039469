import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { UtilitiesService } from '../utilities-service/utilities.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PmsIntegratorService {

  private userToken: string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
  }

  public InitializePMSSync(): Observable<boolean> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }
    return $this.http.get<boolean>(environment.apiUrl + 'api/v1/PMSIntegrator/InitializePMSSync', header).pipe();
  }

  public ForceSyncManual(): Observable<boolean> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }
    return $this.http.get<boolean>(environment.apiUrl + 'api/v1/PMSIntegrator/ForceSyncManual', header).pipe();
  }

}
