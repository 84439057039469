<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">

    <div class="container container-form">
      <div style="padding: 10px;">
        <div class="row row-form-container">
          <form>
            <div class="row">
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Nome</mat-label>
                  <input matInput [(ngModel)]="user.name" (keyup)="checkFields()" name="name">
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Username</mat-label>
                  <input matInput [(ngModel)]="user.username" (keyup)="checkFields()" name="username">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Email</mat-label>
                  <input type="email" matInput [(ngModel)]="user.email" (keyup)="checkFields()" name="email">
                </mat-form-field>
              </div>
              <div *ngIf="user.id == 0" class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Password</mat-label>
                  <input type="password" matInput [(ngModel)]="user.password" (keyup)="checkFields()" name="password">
                </mat-form-field>
              </div>

            </div>

            <div class="row">
              <div *ngIf="user.id > 0" class="col-xl-6 col-md-6 col-sm-12">
                <button class="input-full-width button-form-ptt" color="primary" (click)="callChangePasswordDialog()"
                  mat-raised-button>Alterar
                  Password</button>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Grupo de Utilizadores</mat-label>
                  <mat-select [(ngModel)]="user.userGroupId" name="userGroupId" (selectionChange)="checkFields()">
                    <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup.id">
                      {{ userGroup.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Idioma</mat-label>
                  <mat-select [(ngModel)]="user.languageId" name="languageId" (selectionChange)="checkFields()">
                    <mat-option *ngFor="let language of languages" [value]="language.id">
                      {{ language.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Subempresa</mat-label>
                  <mat-select [(ngModel)]="user.subcompanyId" name="subcompanyId" (selectionChange)="checkFields()">
                    <mat-option *ngFor="let subcompany of subcompanies" [value]="subcompany.id">
                      {{ subcompany.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <div class="input-full-width mat-form-field-flex slide-container">
                  <mat-slide-toggle [(ngModel)]="user.activeNy" (keyup)="checkFields()" name="activeNy">Ativo?
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-sm-12">
                <div class="input-full-width mat-form-field-flex slide-container">
                  <mat-slide-toggle [(ngModel)]="user.accessToConfiguration" (keyup)="checkFields()"
                    name="accessToConfiguration">Acesso às Configurações?
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
            <div class="row">
              <div *ngIf="user.id == 0" class="col-xl-6 col-md-6 col-sm-12">
                <mat-form-field class="input-full-width">
                  <mat-label>Pin</mat-label>
                  <input type="password" matInput [(ngModel)]="user.pin" (keyup)="checkFields()" name="pin">
                </mat-form-field>
              </div>
              <div *ngIf="user.id > 0" class="col-xl-6 col-md-6 col-sm-12">
                <button class="input-full-width button-form-ptt" color="primary" (click)="callChangePinDialog()"
                  mat-raised-button>Alterar Pin</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <button class="form-button button-form-ptt" (click)="goBack()" mat-raised-button color="accent">
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div class="col-6 align-right">
            <button class="form-button button-form-ptt" (click)="saveData()" [disabled]="!enableSave" mat-raised-button
              color="accent">
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>


<ng-template #changePasswordDialog>
  <small mat-dialog-title>Alterar Password</small>
  <mat-dialog-content class="mat-typography">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-md-12 col-sm-12">
          <mat-form-field class="input-full-width">
            <mat-label>Nova Password</mat-label>
            <input cdkFocusInitial type="password" [(ngModel)]="changePasswordData.Password" matInput>
          </mat-form-field>
        </div>
        <div class="col-xl-12 col-md-12 col-sm-12">
          <mat-form-field class="input-full-width">
            <mat-label>Confirmar Nova Password</mat-label>
            <input type="password" [(ngModel)]="confirmPassword" matInput>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button
      [disabled]="!(changePasswordData.Password != '' && changePasswordData.Password != null && changePasswordData.Password == confirmPassword)"
      [mat-dialog-close]="true" class="input-full-width button-form-ptt" color="primary"
      (click)="changePassword()">Alterar
      Password</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #changePinDialog>
  <small mat-dialog-title>Alterar Pin</small>
  <mat-dialog-content class="mat-typography">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-md-12 col-sm-12">
          <mat-form-field class="input-full-width">
            <mat-label>Novo Pin</mat-label>
            <input type="password" cdkFocusInitial [(ngModel)]="changePinData.Pin" matInput>
          </mat-form-field>
        </div>
        <div class="col-xl-12 col-md-12 col-sm-12">
          <mat-form-field class="input-full-width">
            <mat-label>Confirmar Novo Pin</mat-label>
            <input type="password" [(ngModel)]="confirmPin" matInput>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="true"
      [disabled]="!(changePinData.Pin != '' && changePinData.Pin != null && changePinData.Pin == confirmPin)"
      class="input-full-width button-form-ptt" color="primary" (click)="changePin()">Alterar
      Pin</button>
  </mat-dialog-actions>
</ng-template>
