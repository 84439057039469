import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { CreateTranslationModel } from 'src/app/shared/models/CreateTranslationModel.model';
import { TranslationListModel } from 'src/app/shared/models/TranslationListModel.model';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { TranslationModel } from 'src/app/shared/models/TranslationModel.model';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private userToken:string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { 
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
  }

  public GetTranslationsByLanguageId(argLanguageId): Observable<any> {

    const $this = this;

    return $this.http.get<any>(environment.apiUrl + 'api/v1/Translation/TranslationsForWeb/' + argLanguageId).pipe();

  }

  public GetAllColumnNames(): Observable<string[]>{
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }
    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/Translation/GetAllColumnNames', header).pipe();
  }

  public GetTranslationById(id:number): Observable<TranslationModel>{
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }
    return $this.http.get<TranslationModel>(environment.apiUrl + 'api/v1/Translation/' + id,header).pipe();
  }

  public GetAllTranslations(filterParameters: FiltersModel): Observable<TranslationListModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }

    return $this.http.post<TranslationListModel>(environment.apiUrl + 'api/v1/Translation/GetAllTranslations', filterParameters,header).pipe();
  }

  public SaveTranslation(dataToSave: CreateTranslationModel):Observable<any> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }

    return $this.http.post<any>(environment.apiUrl + 'api/v1/Translation', dataToSave,header).pipe();
  }

  public DeleteListOfTranslation(listOfDocuments: any[]):Observable<any> {
    const $this = this;
    let listToDelete = JSON.stringify(listOfDocuments);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }).set('Authorization',  'Bearer ' + $this.userToken),
      body: listOfDocuments,
    };
    return $this.http.delete<any>(environment.apiUrl + 'api/v1/Translation', options).pipe();
  }
}
