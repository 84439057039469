import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SubcompanyService } from 'src/app/services/subcompany/subcompany.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { SubcompanyModel } from 'src/app/shared/models/SubcompanyModel.model';
import * as _ from 'underscore';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';

@Component({
  selector: 'app-subcompany-form',
  templateUrl: './subcompany-form.component.html',
  styleUrls: ['./subcompany-form.component.scss']
})
export class SubcompanyFormComponent implements AfterViewInit, AfterContentChecked {
  subcompany: SubcompanyModel = {
    id: 0,
    name: null,
    companyId: 0,
    contact: null,
    email: null,
    incomingEmailPort: null,
    incomingEmailServer: null,
    outgoingEmailPort: null,
    outgoingEmailServer: null,
    passwordEmail: null,
    requiresSll: false
  };
  arrayOfRequests: any[] = [];
  enableSave: boolean = true;
  hasWalkin: boolean;
  companies: DictionaryModel[];

  constructor(private subcompanyService: SubcompanyService, private loadingService: LoadingService, private dictionariesService: DictionariesService,
    private editDataServiceService: EditDataServiceService, private router: Router, private cdref: ChangeDetectorRef, private utilitiesService: UtilitiesService) {
  }
  ngAfterViewInit(): void {
    const $this = this;
    $this.loadingService.toggle("");


    $this.arrayOfRequests.push($this.dictionariesService.GetCompanyDictionary());

    if ($this.editDataServiceService.subcompanyIdToEdit != 0) {
      $this.arrayOfRequests.push($this.subcompanyService.GetSubcompanyById($this.editDataServiceService.subcompanyIdToEdit));
    }

    if ($this.arrayOfRequests.length == 0) {
      $this.loadingService.toggle("");
    }
    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {

        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];

          switch (i) {
            case 0:
              $this.companies = resp as DictionaryModel[];
              break;
            case 1:
              if ($this.editDataServiceService.subcompanyIdToEdit != 0) {
                let currentSubcompany = resp as SubcompanyModel;

                if (currentSubcompany != null) {
                  $this.subcompany = $this.toLowerKeys(currentSubcompany) as SubcompanyModel;
                }

                $this.editDataServiceService.subcompanyIdToEdit = 0;
                $this.checkFields();
              }
              break;
          }
        }
        $this.loadingService.toggle("");
      });
  }

  checkFields() {
    const $this = this;
    const objKeys = _.keys($this.subcompany);
    const notRequiredFields = ['id', 'appMessages', 'backoffices', 'company', 'contact', 'emailToSends', 'externalApiCalls',
      'groups', 'incomingEmailPort', 'incomingEmailServer', 'kiosks', 'locals', 'outgoingEmailPort', 'outgoingEmailServer', 'passwordEmail',
      'requiresSll', 'userGroupKioskList', 'userGroups', 'users'];
    const requiredNumber = [];

    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.subcompany[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.subcompany[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  ngAfterContentChecked(): void {
    const $this = this;
    $this.checkFields();
    $this.cdref.detectChanges();
  }

  toLowerKeys(obj) {
    let $this = this;
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[$this.camelize(key)] = obj[key];
      return accumulator;
    }, {});
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  clear() {
    const $this = this;
    $this.checkFields();
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/subcompanies"]);
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.subcompanyService.SaveSubcompany($this.subcompany).subscribe(() => {
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/subcompanies"]);
    });
  }
}