import { AfterViewInit, Component, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { forkJoin } from 'rxjs';
import { CompanyService } from 'src/app/services/company/company.service';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { KioskService } from 'src/app/services/kiosk/kiosk.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { KioskListModel } from 'src/app/shared/models/KioskListModel.model';
import { KioskModel } from 'src/app/shared/models/KioskModel.model';
import { LicenseLimitsModel } from 'src/app/shared/models/LicenseLimitsModel.model';
import { CardDispenserType } from 'src/app/shared/models/Utilities/CardDispenserType';
import { EncoderType } from 'src/app/shared/models/Utilities/EncoderType';
import * as _ from 'underscore';

@Component({
  selector: 'app-kiosk-form',
  templateUrl: './kiosk-form.component.html',
  styleUrls: ['./kiosk-form.component.scss']
})

export class KioskFormComponent implements AfterViewInit {
  kiosk: KioskModel = {
    id: 0,
    kioskCode: null,
    name: null,
    activeNy: false,
    groups: null,
    serverAdress: null,
    logo: null,
    logoName: null,
    kioskAddress: null,
    checkinTimeLimit: null,
    nHoursEarlyCheckin: 0,
    serverName: null,
    kioskRestartHour: null,
    isKioskWeb: false,
    printerAddress: null,
    printerTypeId: null,
    scannerTypeId: null,
    printerUseUsbDirect: false,
    printerFullCut: false,
    printerPaperWidth: null,
    printerCOMPort: null,
    useCash: false,
    useMB: false,
    encoderHost: null,
    encoderPort: null,
    encoderName: null,
    cardEncoderType: null,
    cardDispenserType: null,
    cardDispenserCOMPort: null,
    qrCodeReaderCOMPort: null,
    scannerCOMPort: null,
    languageId: null,
    subcompanyId: null,
    emailsToNotifyCardDispenserAlerts: null,
    tags: null,
    isWalkinEnable: false,
    useOnScreenSignature: false,
    earlyCheckinMaxHour: null,
    earlyCheckinTax: null,
    inactivityTimeout: 0,
    kioskLocals: []
  };
  subcompanies: DictionaryModel[];
  locals: DictionaryModel[];
  languages: DictionaryModel[];
  arrayOfRequests: any[] = [];
  enableSave: boolean = true;
  selectedFile: File;
  selectedImagePreview: string;
  hasFile: boolean = false;
  logoInput: string;
  cardDispenserTypes = CardDispenserType;
  encoderTypes = EncoderType;
  kioksLimits: number;
  filters: FiltersModel = {
    ColumnsToSearch: [],
    CurrentPage: 0,
    PageSize: 10,
    OrderColumn: null,
    OrderType: null,
    SearchText: null,
    FilterTags: []
  };
  hasWalkin: boolean;

  constructor(private kioskService: KioskService, private loadingService: LoadingService, private editDataServiceService: EditDataServiceService,
    private companyService: CompanyService, private dictionariesService: DictionariesService, private router: Router,
    private imageCompress: NgxImageCompressService, private sanitizer: DomSanitizer, private utilitiesService: UtilitiesService) {
  }

  checkFields() {
    const $this = this;
    const objKeys = _.keys($this.kiosk);
    const notRequiredFields = ['tags', 'group', 'key', 'kioskRestartHour', 'checkinTimeLimit', 'gateControllerCOMPort', 'language', 'printerAddress'
      , 'printerCOMPort', 'printerFullCut', 'printerPaperWidth', 'printerType', 'printerTypeId', 'scannerCOMPort', 'scannerType', 'scannerTypeId', 'subcompany'
      , 'cardDispenserCOMPort', 'hopperCOMPort', 'hopperChannel', 'encoderName', 'encoderHost', 'encoderPort', 'nHoursEarlyCheckin', 'isKioskWeb', 'activeNy'
      , 'singleControllerSmart', 'useCash', 'useMB', 'printerUseUsbDirect', 'smartpayoutChannel', 'smartPayoutCOMPort', 'qrCodeReaderCOMPort', 'poscomPort'
      , 'hopperCOMPort', 'hopperChannel', 'mbDeviceType', 'bookings', 'userGroupKioskList', 'id', 'isWalkinEnable', 'cardDispenserType', 'useOnScreenSignature'
      , 'earlyCheckinMaxHour', 'earlyCheckinTax', 'inactivityTimeout', 'kioskTubeLevels', 'kioskCashboxLevels', 'kioskMoneyTransactions', 'kioskPeriods', 'kioskLocals', 'groups'];
    const requiredNumber = ['languageId', 'subcompanyId', 'groupId', 'emailsToNotifyCardDispenserAlerts'];

    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.kiosk[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.kiosk[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  onFileChanged(event) {
    const $this = this;
    $this.selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL($this.selectedFile);
    reader.onload = () => {
      $this.selectedImagePreview = reader.result.toString();
      if ($this.selectedImagePreview.length > 0 && $this.selectedImagePreview.split(',').length > 0 && $this.selectedImagePreview.length <= 10000000) {

        $this.kiosk.logoName = $this.selectedFile.name;

        $this.imageCompress.compressFile($this.selectedImagePreview, -2, 100, 50).then(result => {
          $this.kiosk.logo = result.split(',')[1];
          $this.hasFile = true;
          $this.checkFields();
        });
      } else {
        $this.clear();
      }
    };
  }

  ngAfterViewInit(): void {
    const $this = this;
    $this.loadingService.toggle("");

    $this.arrayOfRequests.push($this.companyService.GetCompanyLimits());
    $this.arrayOfRequests.push($this.dictionariesService.GetSubcompanyDictionary());
    $this.arrayOfRequests.push($this.dictionariesService.GetLanguageDictionary());
    $this.arrayOfRequests.push($this.dictionariesService.GetLocalsDictionary());

    if ($this.editDataServiceService.kioskIdToEdit != 0) {
      $this.arrayOfRequests.push($this.kioskService.GetKioskById($this.editDataServiceService.kioskIdToEdit));
    } else {
      $this.checkFields();
      $this.arrayOfRequests.push($this.kioskService.GetAllKiosks($this.filters));
    }

    if ($this.arrayOfRequests.length == 0) {
      $this.loadingService.toggle("");
    }
    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {

        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];

          switch (i) {
            case 0:
              let licenseLimits = resp as LicenseLimitsModel;
              $this.kioksLimits = licenseLimits.kioskLimits;
              $this.hasWalkin = licenseLimits.hasWalkinFunctionality;
              break;
            case 1:
              $this.subcompanies = resp as DictionaryModel[];
              break;
            case 2:
              $this.languages = resp as DictionaryModel[];
              break;
            case 3:
              $this.locals = resp as DictionaryModel[];
              break;
            case 4:
              if ($this.editDataServiceService.kioskIdToEdit != 0) {
                let currentKiosk = resp as KioskModel;

                if (currentKiosk != null) {
                  $this.kiosk = $this.toLowerKeys(currentKiosk) as KioskModel;
                }

                if (currentKiosk.logo != null && currentKiosk.logo != "") {
                  $this.selectedImagePreview = $this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, $this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + currentKiosk.logo));
                  $this.kiosk.logo = $this.selectedImagePreview.split(',')[1];
                  $this.hasFile = true;
                }
                $this.editDataServiceService.kioskIdToEdit = 0;
                $this.checkFields();
              } else {
                let kiosksList = resp as KioskListModel;
                if (kiosksList.itemsCounter >= $this.kioksLimits) {
                  $this.goBack();
                  $this.loadingService.toggle("");
                  return;
                }
              }
              break;
          }
        }
        $this.loadingService.toggle("");
      });

  }

  toLowerKeys(obj) {
    let $this = this;
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[$this.camelize(key)] = obj[key];
      return accumulator;
    }, {});
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  clear() {
    const $this = this;
    $this.selectedImagePreview = "../../assets/imgs/scan_erro.png";
    $this.logoInput = "";
    $this.kiosk.logo = "";
    $this.hasFile = false;
    $this.checkFields();
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/kiosks"]);
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.kioskService.SaveKiosk($this.kiosk).subscribe(() => {
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/kiosks"]);
    });
  }
}
