import { ChangeDetectorRef, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { RoomTypesService } from 'src/app/services/roomTypes/room-types.service';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { LanguageModel } from 'src/app/shared/models/LanguageModel.model';
import { TranslationModel } from 'src/app/shared/models/TranslationModel.model';
import { DocumentStateType } from 'src/app/shared/models/enums/document-state-type';
import { TranslationStateType } from 'src/app/shared/models/enums/translation-state-type';
import { TranslationType } from 'src/app/shared/models/enums/translation-type';
import { RoomTypeModel } from 'src/app/shared/models/room-type-model';
import * as _ from 'underscore';
import { CustomSnackBarComponent } from '../custom-snack-bar/custom-snack-bar.component';
import { PhotoPreviewDialogComponent } from '../photo-preview-dialog/photo-preview-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-room-type-form',
  templateUrl: './room-type-form.component.html',
  styleUrls: ['./room-type-form.component.scss']
})
export class RoomTypeFormComponent {

  roomType: RoomTypeModel = {
    id: 0,
    name: null,
    subcompanyId: 0,
    localId: 0,
    state: DocumentStateType.Unknown,
    description: null
  };
  arrayOfRequests: any[] = [];
  enableSave: boolean = true;
  subcompanies: DictionaryModel[];
  locals: DictionaryModel[];
  languages: LanguageModel[];
  translations: TranslationModel[] = [];
  documentStateTypes = DocumentStateType;
  isEdit: boolean = false;
  roomPhotoMaxSize = 2 * 1024 * 1024;

  constructor(private roomTypesService: RoomTypesService, private loadingService: LoadingService, private dictionariesService: DictionariesService,
    private editDataServiceService: EditDataServiceService, private router: Router, private cdref: ChangeDetectorRef, private utilitiesService: UtilitiesService,
    private languageService: LanguageService, private cdr: ChangeDetectorRef, private snackBar: MatSnackBar, private dialog: MatDialog) {
  }
  ngOnInit(): void {
    const $this = this;
    $this.loadingService.toggle("");


    $this.arrayOfRequests.push($this.dictionariesService.GetSubcompanyDictionary());
    $this.arrayOfRequests.push($this.dictionariesService.GetLocalsDictionary());
    $this.arrayOfRequests.push($this.languageService.GetLanguages());

    if ($this.editDataServiceService.roomTypeIdToEdit != 0) {
      $this.isEdit = true;
      $this.arrayOfRequests.push($this.roomTypesService.GetRoomTypeById($this.editDataServiceService.roomTypeIdToEdit));
    }

    if ($this.arrayOfRequests.length == 0) {
      $this.loadingService.toggle("");
    }
    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {

        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];

          switch (i) {
            case 0:
              $this.subcompanies = resp as DictionaryModel[];
              break;
            case 1:
                $this.locals = resp as DictionaryModel[];
                break;
            case 2:
              $this.languages = resp as LanguageModel[];
              if ($this.editDataServiceService.roomTypeIdToEdit == 0) {
                $this.renderTranslations();
              }
              break;
            case 3:
              if ($this.editDataServiceService.roomTypeIdToEdit != 0) {
                let currentRoomType = resp as RoomTypeModel;
                if (currentRoomType != null) {
                  $this.roomType = $this.toLowerKeys(currentRoomType) as RoomTypeModel;
                }

                $this.editDataServiceService.roomTypeIdToEdit = 0;
                $this.renderTranslations();
                $this.checkFields();
              }
              break;
          }
        }
        $this.loadingService.toggle("");
      });
  }

  renderTranslations() {

    const $this = this;
    for (let i = 0; i < $this.languages.length; i++) {
      const currentLanguage = $this.languages[i];
      let currentTranslationName: TranslationModel = null;
      let currentTranslationDescription: TranslationModel = null;
      if ($this.roomType.translations == null) {
        $this.roomType.translations = [];
      }
      currentTranslationName = _.findWhere($this.roomType.translations, { languageId: currentLanguage.id, translationKey: 'RoomType_Name' });
      currentTranslationDescription = _.findWhere($this.roomType.translations, { languageId: currentLanguage.id, translationKey: 'RoomType_Description' });

      if (currentTranslationName != null && currentTranslationName != undefined) {
        $this.translations.push(currentTranslationName);
      } else {
        $this.translations.push({
          id: 0,
          description: '',
          translationKey: 'RoomType_Name',
          languageId: currentLanguage.id,
          state: TranslationStateType.Active,
          type: TranslationType.RoomType,
          roomTypeId: 0
        });
      }

      if (currentTranslationDescription != null && currentTranslationDescription != undefined) {
        $this.translations.push(currentTranslationDescription);
      } else {
        $this.translations.push({
          id: 0,
          description: '',
          translationKey: 'RoomType_Description',
          languageId: currentLanguage.id,
          state: TranslationStateType.Active,
          type: TranslationType.RoomType,
          roomTypeId: 0
        });
      }

      this.translations.forEach(translation => {
        this.translationMap.set(translation.languageId + '_' + translation.translationKey, translation.description);
      });

      this.cdr.detectChanges();
    }

  }

  triggerFileInput(roomPhotosInput: HTMLInputElement): void {
    roomPhotosInput.click();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const $this = this;
    if (input.files) {
      Array.from(input.files).forEach((file, index) => {
        if (file.size <= $this.roomPhotoMaxSize) {
          const reader = new FileReader();
          reader.onload = () => {

            if ($this.roomType.roomPhotos == null || $this.roomType.roomPhotos == undefined) {
              $this.roomType.roomPhotos = [];
            }

            $this.roomType.roomPhotos.push({
              id: 0,
              fileName: file.name,
              file: reader.result?.toString()?.split(',')[1],
              fileToDisplay: reader.result as string,
              mimeType: file.type,
              roomTypeId: 0
            });
          };
          reader.readAsDataURL(file);
        } else {
          $this._renderSnackbar(`O ficheiro <b>${file.name}</b> excede o limite de tamanho de ficheiro de <b>2MB</b>.`);
        }
      });
    }
  }

  removePhoto(index: number) {
    const $this = this;
    if (index > -1 && index < $this.roomType.roomPhotos.length) {
      $this.roomType.roomPhotos.splice(index, 1);
    }
  }

  openPhotoPreview(index: number): void {
    const $this = this;
    $this.dialog.open(PhotoPreviewDialogComponent, {
      width: '80%',
      data: { photos: $this.roomType.roomPhotos, currentIndex: index }
    });
  }

  translationMap: Map<string, string> = new Map();

  getTranslation(languageId: number, translationKey: string): string {
    return this.translationMap.get(languageId + '_' + translationKey) || '';
  }

  setTranslation(languageId: number, translationKey: string, description: string) {
    this.translationMap.set(languageId + '_' + translationKey, description);
    const translation = this.translations.find(t => t.languageId === languageId && t.translationKey === translationKey);
    if (translation) {
      translation.description = description;
    }
    this.cdr.detectChanges();
  }

  trackByLanguageId(index: number, language: any): number {
    return language.id;
  }

  checkFields() {
    const $this = this;
    const objKeys = _.keys($this.roomType);
    const notRequiredFields = ['id', 'translations', 'subcompany', 'roomPhotos', 'local'];
    const requiredNumber = [];

    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.roomType[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.roomType[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  ngAfterContentChecked(): void {
    const $this = this;
    $this.checkFields();
    $this.cdref.detectChanges();
  }

  toLowerKeys(obj) {
    let $this = this;
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[$this.camelize(key)] = obj[key];
      return accumulator;
    }, {});
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  clear() {
    const $this = this;
    $this.checkFields();
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/room-types"]);
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.roomType.translations = $this.translations;
    $this.roomTypesService.SaveRoomType($this.roomType).subscribe(() => {
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/room-types"]);
    });
  }

  _renderSnackbar(message: string) {
    const $this = this;
    $this.snackBar.openFromComponent(CustomSnackBarComponent, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 10 * 1000,
      panelClass: ["custom-snackbar"],
      data: {
        message: message,
        closeButtonText: 'Fechar'
      }
    });
  }

}
