export enum TubeType {
    None = 0,
    OneCent = 1,
    TwoCents = 2,
    FiveCents = 5,
    TenCents = 10,
    TwentyCents = 20,
    FiftyCents = 50,
    OneEuro = 100,
    TwoEuros = 200,
    FiveEuros = 500,
    TenEuros = 1000,
    TwentyEuros = 2000,
    FiftyEuros = 5000,
    OneHundredEuros = 10000,
    TwoHundredEuros = 20000,
    FiveHundredEuros = 50000
}

export const TubeTypeLabel = new Map<TubeType, string>([
    [TubeType.None, '0,00€'],
    [TubeType.OneCent, '0,01€'],
    [TubeType.TwoCents, '0,02€'],
    [TubeType.FiveCents, '0,05€'],
    [TubeType.TenCents, '0,10€'],
    [TubeType.TwentyCents, '0,20€'],
    [TubeType.FiftyCents, '0,50€'],
    [TubeType.OneEuro, '1,00€'],
    [TubeType.TwoEuros, '2,00€'],
    [TubeType.FiveEuros, '5,00€'],
    [TubeType.TenEuros, '10,00€'],
    [TubeType.TwentyEuros, '20,00€'],
    [TubeType.FiftyEuros, '50,00€'],
    [TubeType.OneHundredEuros, '100,00€'],
    [TubeType.TwoHundredEuros, '200,00€'],
    [TubeType.FiveHundredEuros, '500,00€']
  ]);