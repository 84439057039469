<div class="container page-container-yk">
  <div class="row page-header">
    <h3 class="page-header">{{ 'AddGuestsTitleSEFFrontOffice' | translate }}</h3>
  </div>

  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-8">
      <div class="kiosk-container sef-form">
        <div *ngIf="questionNumber == 0" class="question question-xl question-one">
          <label for="questionOne">{{ 'SEFUploadFileOnline' | translate }}</label>
          <div class="upload-document">
            <img [src]="selectedImagePreview">
            <input style="display: none;" accept=".png,.jpg" [(ngModel)]="documentInput" type="file"
              (change)="onFileChanged($event)" #fileInput>
            <div class="button-container">
              <button type="button" (click)="fileInput.click()" class="btn btn-lg btn-light">Upload</button>
              <button type="button" (click)="clear()" class="btn btn-lg btn-light">Clear</button>
            </div>
          </div>
        </div>
        <div *ngIf="questionNumber == 1" class="question question-two">
          <label for="questionTwo">{{ 'SEFFirstQuestionFrontOffice' | translate }}</label>
          <input type="text" class="form-control" id="questionTwo" [(ngModel)]="questionTwo"
            (keyup)="changeValue($event)" placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 2" class="question question-three">
          <label for="questionThree">{{ 'SEFSecondQuestionFrontOffice' | translate }}</label>
          <input type="text" class="form-control" id="questionThree" [(ngModel)]="questionThree"
            (keyup)="changeValue($event)" placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 3" class="question question-four">
          <label for="questionFour">{{ 'SEFThirdQuestionFrontOffice' | translate }}</label>
          <input type="text" class="form-control" id="questionFour" (keyup)="changeValue($event)"
            [(ngModel)]="questionFour" [ngbTypeahead]="searchCountry" (selectItem)="selectValueCountries($event)"
            (focus)="focus$.next($any($event).target.value)" (click)="click$.next($any($event).target.value)"
            #instanceCountry="ngbTypeahead" placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 4" class="question question-fifth">
          <label for="questionFifth">{{ 'SEFFourthQuestionFrontOffice' | translate }}</label>
          <input type="date" class="form-control" id="questionFifth" [(ngModel)]="questionFifth"
            (change)="changeValue($event)" placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 5" class="question question-sixth">
          <label for="questionSixth">{{ 'SEFFifthQuestionFrontOffice' | translate }}</label>
          <input type="text" class="form-control" id="questionSixth" [(ngModel)]="questionSixth"
            (keyup)="changeValue($event)" placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 6" class="question question-seventh">
          <label for="questionSeventh">{{ 'SEFSixthQuestionFrontOffice' | translate }}</label>
          <input type="text" class="form-control" id="questionSeventh" [(ngModel)]="questionSeventh"
            (keyup)="changeValue($event)" placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 7" class="question question-eighth">
          <label for="questionEighth">{{ 'SEFSeventhQuestionFrontOffice' | translate }}</label>
          <input type="text" class="form-control" id="questionEighth" [(ngModel)]="questionEighth"
            (keyup)="changeValue($event)" [ngbTypeahead]="searchDocumentType"
            (focus)="focus$.next($any($event).target.value)" (click)="click$.next($any($event).target.value)"
            #instanceDocumentType="ngbTypeahead" (selectItem)="selectDocumentTypes($event)"
            placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 8" class="question question-ninth">
          <label for="questionNinth">{{ 'SEFEighthQuestionFrontOffice' | translate }}</label>
          <input type="text" class="form-control" id="questionNinth" [(ngModel)]="questionNinth"
            (keyup)="changeValue($event)" [ngbTypeahead]="searchCountry" (selectItem)="selectValueCountries($event)"
            (focus)="focus$.next($any($event).target.value)" (click)="click$.next($any($event).target.value)"
            #instanceCountry="ngbTypeahead" placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 9" class="question question-tenth">
          <label for="questionTenth">{{ 'SEFNinthQuestionFrontOffice' | translate }}</label>
          <input type="text" class="form-control" id="questionTenth" [ngbTypeahead]="searchCountry"
            (selectItem)="selectValueCountries($event)" [(ngModel)]="questionTenth" (keyup)="changeValue($event)"
            (focus)="focus$.next($any($event).target.value)" (click)="click$.next($any($event).target.value)"
            #instanceCountry="ngbTypeahead" placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 10" class="question question-eleventh">
          <label for="questionEleventh">{{ 'SEFTenthQuestionFrontOffice' | translate }}</label>
          <input type="text" class="form-control" id="questionEleventh" [(ngModel)]="questionEleventh"
            (keyup)="changeValue($event)" placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 11" class="question question-twelfth">
          <form [formGroup]="phoneForm">
            <label for="questionTwelfth">{{ 'SEFEleventhQuestionFrontOffice' | translate }}</label>
            <ngx-intl-tel-input [cssClass]="'form-control'" [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Portugal" [maxLength]="15"
              [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National"
              name="phone" id="questionTwelfth" (ngModelChange)="changeValue($event)" formControlName="phone">
            </ngx-intl-tel-input>
          </form>
        </div>
        <div *ngIf="questionNumber == 12" class="question question-thirteenth">
          <label for="questionThirteenth">{{ 'SEFTwelfthQuestionFrontOffice' | translate }}</label>
          <input type="email" class="form-control" id="questionThirteenth" [(ngModel)]="questionThirteenth"
            (keyup)="changeValue($event)" email placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}">
        </div>
        <div *ngIf="questionNumber == 13" class="question question-fourteenth">
          <label for="questionFourteenth">{{ 'SEFThirteenthQuestionFrontOffice' | translate }}</label>
          <select class="form-control" id="questionFourteenth" [(ngModel)]="questionFourteenth" (change)="changeValue($event)">
            <option *ngFor="let gender of guestGenderOptions" value="{{ gender.value }}">{{ gender.description }}</option>
          </select>
          <!--input type="email" class="form-control" id="questionFourteenth" [(ngModel)]="questionThirteenth"
            (keyup)="changeValue($event)" email placeholder="{{ 'SearchPlaceholderFrontOffice' | translate }}"-->
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">
      <button (click)="prevPage()" type="button"
        class="btn btn-lg btn-secondary">{{ 'ButtonBackFrontOffice' | translate }}</button>
    </div>
    <div class="col text-center">
      <button (click)="nextPage()" [disabled]="disableNextButton" type="button"
        class="btn btn-lg btn-success">{{ 'ButtonNextFrontOffice' | translate }}</button>
    </div>
  </div>

  <div class="row row-action-two">
    <div class="col text-center">
      <p (click)="exit()" class="link-button btn-lg cancel-button">{{ 'ButtonCancelFrontOffice' | translate }}</p>
    </div>
    <div class="col text-center">

    </div>
  </div>

</div>
