<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="qm-card">
      <div class="container container-form">
        <form>
          <div class="row row-form">
            <div class="col-xl-4 col-md-6 col-sm-6">
              <mat-form-field class="input-full-width">
                <mat-label>Data de Publicação(*)</mat-label>
                <input matInput [matDatepicker]="picker" readonly [(ngModel)]="term.publishDate" name="publishDate" (selectionChange)="checkFields()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-12">
              <mat-form-field class="input-full-width">
                <mat-label>Idioma (*)</mat-label>
                <mat-select [(ngModel)]="term.languageId" name="languageId" (selectionChange)="checkFields()">
                  <mat-option *ngFor="let language of languages" [value]="language.id">
                    {{ language.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-6">
              <div class="input-full-width mat-form-field-flex slide-container">
                <mat-slide-toggle [(ngModel)]="term.lastVersionNy" (change)="checkFields()" name="lastVersionNy">Última
                  Versão?
                </mat-slide-toggle>
              </div>
            </div>
            <div class="col-xl-12 col-md-12 col-sm-12">
              <div class="NgxEditor__Wrapper ngxeditor-container">
                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
                <ngx-editor [editor]="editor" [(ngModel)]="html" (focusout)="checkFields()" name="Text"
                  placeholder="Descrição (*)">
                </ngx-editor>
              </div>
            </div>
          </div>
        </form>
      </div>
  
      <div class="row row-footer">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <button class="form-button button-form-ptt" (click)="goBack()" mat-raised-button color="accent">
                <mat-icon aria-hidden="false">arrow_back</mat-icon>
                VOLTAR
              </button>
            </div>
            <div class="col-6 align-right">
              <button class="form-button button-form-ptt" (click)="saveData()" [disabled]="!enableSave" mat-raised-button color="accent">
                <mat-icon aria-hidden="false">save</mat-icon>
                GRAVAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  