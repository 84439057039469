<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="row">
      <div class="col-xl-8 col-md-6 col-sm-12">
        <mat-form-field>
          <mat-label>Filtro</mat-label>
          <input
            matInput
            (keyup)="applyFilter($event)"
            [(ngModel)]="filters.SearchText"
            #input
          />
        </mat-form-field>
      </div>
      <div class="col-xl-3 col-md-4 col-sm-12">
        <mat-form-field>
          <mat-label>Intervalo de Datas</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              [formControl]="startDate"
              placeholder="Data Início"
            />
            <input matEndDate [formControl]="endDate" placeholder="Data Fim" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="col-xl-1 col-md-2 col-sm-12">
        <div class="search-buttons-col">
          <button mat-flat-button color="primary" (click)="exportToCSV()">
            <mat-icon>download</mat-icon>
          </button>
          <button mat-flat-button color="primary" (click)="search()">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="mat-elevation-z8 search-container">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="select">
            <th mat-header-cell [ngClass]="'custom-w-75'" *matHeaderCellDef>
              <mat-checkbox
                (keyup)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell [ngClass]="'custom-w-75'" *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="snapTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>DATA</th>
            <td mat-cell *matCellDef="let row">{{ row.snapTime }}</td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              CÓD. PERIODO
            </th>
            <td mat-cell *matCellDef="let row">{{ row.kioskPeriod.code }}</td>
          </ng-container>

          <!-- Operation Type Column -->
          <ng-container matColumnDef="operationType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              TIPO DE OPERAÇÃO
            </th>
            <td mat-cell *matCellDef="let row">
              {{ getMoneyOperationTypeLabel(row.operationType) }}
            </td>
          </ng-container>

          <!-- Kiosk Type Column -->
          <ng-container matColumnDef="kiosk">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>QUIOSQUE</th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.kiosk != null && row.kiosk.name != null">{{ row.kiosk.name }}</span>
              <span *ngIf="!(row.kiosk != null && row.kiosk.name != null)">-</span>
            </td>
          </ng-container>

          <!-- Operation Type Column -->
          <ng-container matColumnDef="paymentMethod">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              MEIO DE PAGAMENTO
            </th>
            <td mat-cell *matCellDef="let row">
              {{ getPaymentMethodLabel(row.paymentMethod) }}
            </td>
          </ng-container>

          

          <!-- Operation Type Column -->
          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              ESTADO
            </th>
            <td mat-cell *matCellDef="let row">
              {{ getTransactionStateLabel(row.state) }}
            </td>
          </ng-container>

          <!-- Value Column -->
          <ng-container matColumnDef="totalValue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              MONTANTE TOTAL
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.value | number : "1.2-2" }} €
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="selection.toggle(row)"
          ></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">
              No data matching the filter "{{ input.value }}"
            </td>
          </tr>
        </table>
      </div>

      <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
    </div>
  </mat-card>
</div>
