import { KioskMoneyMaintenanceTransactionModel } from './../../shared/models/kiosk-money-maintenance-transaction-model';
import { AfterViewChecked, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { KioskMoneyMaintenanceTransactionService } from 'src/app/services/kioskMoneyMaintenanceTransaction/kiosk-money-maintenance-transaction.service';
import * as moment from 'moment';
import { MaintenanceOperationType, MaintenanceOperationTypeLabel } from 'src/app/shared/models/enums/maintenance-operation-type';
import { FormControl } from '@angular/forms';
import * as _ from 'underscore';

@Component({
  selector: 'app-cash-holdings',
  templateUrl: './cash-holdings.component.html',
  styleUrls: ['./cash-holdings.component.scss']
})
export class CashHoldingsComponent implements AfterViewChecked {

  displayedColumns: string[] = ['code', 'snapTime', 'user', 'operationType', 'kiosk', 'totalValue'];
  dataSource: MatTableDataSource<KioskMoneyMaintenanceTransactionModel>;
  startDate = new FormControl();
  endDate = new FormControl();
  editMode: boolean = false;
  numSelected: number;
  columnList: string[];
  filters: FiltersModel = {
    ColumnsToSearch: [],
    CurrentPage: 0,
    PageSize: 10,
    OrderColumn: null,
    OrderType: null,
    SearchText: null,
    FilterTags: []
  };
  kioksLimits: number;
  addKiosk: boolean;
  maintenanceOperationTypes = MaintenanceOperationType;
  maintenanceOperationTypesL = MaintenanceOperationTypeLabel;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService, private kioskMoneyMaintenanceTransactionService: KioskMoneyMaintenanceTransactionService, private cdRef: ChangeDetectorRef) {

    const $this = this;
    $this.startDate.valueChanges.subscribe(value => {
      $this.filters.StartDate = value;
    });

    $this.endDate.valueChanges.subscribe(value => {
      $this.filters.EndDate = value;
    });

    $this.loadingService.toggle("");

    $this.kioskMoneyMaintenanceTransactionService.GetAllColumnNames().subscribe((resp: string[]) => {
      $this.columnList = resp;
      $this.refreshList();
    });
  }

  selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const $this = this;
    if ($this.dataSource == null || $this.dataSource == undefined) {
      return false;
    } else {
      $this.numSelected = $this.selection.selected.length;
      const numRows = $this.dataSource.data.length;
      return $this.numSelected === numRows;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngAfterViewChecked() {
    const $this = this;
    $this.cdRef.detectChanges();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  search() {
    const $this = this;
    $this.loadingService.toggle("");
    $this.refreshList();
  }

  refreshList() {
    const $this = this;


    if ($this.paginator != null) {
      $this.filters.PageSize = $this.paginator.pageSize;
      $this.filters.CurrentPage = $this.paginator.pageIndex;
    }

    $this.selection = new SelectionModel<any>(true, []);
    $this.numSelected = 0;
    $this.kioskMoneyMaintenanceTransactionService.GetAllKioskMoneyMaintenanceTransactions($this.filters).subscribe((resp) => {
      if (resp != null) {
        for (let index = 0; index < resp.kioskMoneyMaintenanceTransactions.length; index++) {
          const transaction: KioskMoneyMaintenanceTransactionModel = resp.kioskMoneyMaintenanceTransactions[index];
          transaction.snapTime = moment(transaction.snapTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY  HH:mm:ss');
        }
        $this.dataSource = new MatTableDataSource(resp.kioskMoneyMaintenanceTransactions);
        $this.dataSource.paginator = $this.paginator;
        $this.dataSource.sort = $this.sort;
        $this.loadingService.toggle("");
      }
    });
  }

  getMaintenanceOperationTypeLabel(type: MaintenanceOperationType): string {
    return MaintenanceOperationTypeLabel.get(type) || '-';
  }

  exportToCSV() {
    const $this = this;
    const csvData = $this.convertToCSV($this.dataSource.filteredData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none');
    a.href = url;
    a.download = 'cash-holdings-'+  moment().format('YYYYMMDD-HHmmss') +'.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private convertToCSV(dataToConvert: KioskMoneyMaintenanceTransactionModel[]): string {
    const $this = this;
    const headers = ['CÓDIGO PERIODO', 'DATA', 'UTILIZADOR', 'TIPO DE OPERAÇÃO', 'QUIOSQUE', 'MONTANTE TOTAL'];
    const rows = [];
    for (let i = 0; i < dataToConvert.length; i++) {
      const data = dataToConvert[i];
      const row = [];
      row.push(data.kioskPeriod.code);
      row.push(data.snapTime);
      if (data.user != null) {
        row.push(data.user.name);
      } else {
        row.push('-');
      }
      row.push($this.getMaintenanceOperationTypeLabel(data.operationType));
      row.push(data.kioskPeriod.kiosk.name);
      row.push(data.totalValue.toFixed(2));
      rows.push(row.join(','));
    }
    return `${headers}\n${rows.join('\n')}`;
  }
}
