import { Component, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from "underscore";
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { DictionariesService } from 'src/app/services/dictionaries/dictionaries.service';
import { EditDataServiceService } from 'src/app/services/editDataService/edit-data-service.service';
import { DictionaryModel } from 'src/app/shared/models/DictionaryModel.model';
import { UserModel } from 'src/app/shared/models/UserModel.model';
import { CreateUserModel } from 'src/app/shared/models/CreateUserModel.model';
import { ChangePasswordModel } from 'src/app/shared/models/ChangePasswordModel.model';
import { ChangePinModel } from 'src/app/shared/models/ChangePinModel.model';
import { forkJoin } from 'rxjs';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements AfterViewInit {

  @ViewChild('changePasswordDialog') changePasswordDialog: TemplateRef<any>;
  @ViewChild('changePinDialog') changePinDialog: TemplateRef<any>;

  changePasswordData: ChangePasswordModel = {
    Id: 0,
    Password: null,
    Username: null
  }
  confirmPassword: string;

  changePinData: ChangePinModel = {
    Id: 0,
    Pin: null,
    Username: null
  }
  confirmPin: string;
  arrayOfRequests: any[] = [];
  dialogRefChangePassword: any;
  dialogRefChangePin: any;

  constructor(private userService: UserService, private dictionariesService: DictionariesService,
    private loadingService: LoadingService, private editDataServiceService: EditDataServiceService,
    private router: Router, private dialog: MatDialog, private utilitiesService: UtilitiesService) {
  }

  callChangePasswordDialog() {
    const $this = this;
    $this.dialogRefChangePassword = $this.dialog.open($this.changePasswordDialog);
    $this.dialogRefChangePassword.afterClosed().subscribe(result => {
      // Note: If the user clicks outside the dialog or presses the escape key, there'll be no result
      if (result !== undefined) {
        if (result === 'yes') {
          // TODO: Replace the following line with your code.
          console.log('User clicked yes.');
        } else if (result === 'no') {
          // TODO: Replace the following line with your code.
          console.log('User clicked no.');
        }
      }
    })
  }

  callChangePinDialog() {
    const $this = this;
    $this.dialogRefChangePin = $this.dialog.open($this.changePinDialog);
    $this.dialogRefChangePin.afterClosed().subscribe(result => {
      // Note: If the user clicks outside the dialog or presses the escape key, there'll be no result
      if (result !== undefined) {
        if (result === 'yes') {
          // TODO: Replace the following line with your code.
          console.log('User clicked yes.');
        } else if (result === 'no') {
          // TODO: Replace the following line with your code.
          console.log('User clicked no.');
        }
      }
    })
  }

  changePassword() {

    const $this = this;

    $this.changePasswordData = {
      Id: $this.user.id,
      Username: $this.user.username,
      Password: $this.changePasswordData.Password
    }

    $this.dialogRefChangePassword.close();

    $this.userService.ChangePassword($this.changePasswordData).subscribe(resp => {
      $this.confirmPassword = null;
      $this.changePasswordData.Password = null;
    });

  }

  changePin() {

    const $this = this;

    $this.changePinData = {
      Id: $this.user.id,
      Username: $this.user.username,
      Pin: $this.changePinData.Pin
    }

    $this.dialogRefChangePin.close();

    $this.userService.ChangePin($this.changePinData).subscribe(resp => {
      $this.confirmPin = null;
      $this.changePinData.Pin = null;
    });

  }

  states: DictionaryModel[];
  currentUser: UserModel = new UserModel();
  languages: DictionaryModel[];
  subcompanies: DictionaryModel[];
  userGroups: DictionaryModel[];
  enableSave: boolean = true;
  hide = true;
  userPhoto: string;
  user: CreateUserModel = {
    id: 0,
    accessToConfiguration: false,
    activeNy: false,
    email: null,
    languageId: 0,
    name: null,
    password: null,
    pin: null,
    subcompanyId: 0,
    userGroupId: 0,
    username: null
  };
  userEdit: boolean;

  checkFields() {
    const $this = this;
    const objKeys = _.keys($this.user);
    const notRequiredFields = ['id', 'activeNy', 'accessToConfiguration', 'pin', 'activationToken', 'userGroup', 'language', 'subcompany'];

    if ($this.userEdit) {
      notRequiredFields.push('password');
    }

    const requiredNumber = [];
    for (let index = 0; index < objKeys.length; index++) {
      const key = objKeys[index];
      if (!notRequiredFields.includes(key)) {
        if ($this.utilitiesService.isNullOrEmpty($this.user[key]) && !requiredNumber.includes(key)) {
          $this.enableSave = false;
          return false;
        } else {
          if (requiredNumber.includes(key) && $this.user[key] == 0) {
            $this.enableSave = false;
            return false;
          } else {
            $this.enableSave = true;
          }
        }
      }
    }
  }

  ngAfterViewInit(): void {
    const $this = this;
    $this.loadingService.toggle("");

    $this.arrayOfRequests.push($this.dictionariesService.GetLanguageDictionary());
    $this.arrayOfRequests.push($this.dictionariesService.GetSubcompanyDictionary());
    $this.arrayOfRequests.push($this.dictionariesService.GetUserGroupDictionary());

    if ($this.editDataServiceService.userIdToEdit != 0) {
      $this.userEdit = true;
      $this.arrayOfRequests.push($this.userService.GetUserById($this.editDataServiceService.userIdToEdit));
    }

    forkJoin($this.arrayOfRequests)
      .subscribe(subscriberArray => {

        for (let i = 0; i < subscriberArray.length; i++) {
          const resp = subscriberArray[i];

          switch (i) {
            case 0:
              $this.languages = resp as DictionaryModel[];
              break;
            case 1:
              $this.subcompanies = resp as DictionaryModel[];
              break;
            case 2:
              $this.userGroups = resp as DictionaryModel[];
              break;
            case 3:
              $this.user = resp as UserModel;
              $this.currentUser = resp as UserModel;
              $this.loadingService.toggle("");
              break;
          }
        }

        if ($this.editDataServiceService.userIdToEdit == 0) {
          $this.loadingService.toggle("");
        } else {
          $this.editDataServiceService.userIdToEdit = 0;
        }
      });
  }

  goBack() {
    const $this = this;
    $this.router.navigate(["backoffice/users"]);
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");

    $this.userService.SaveUser($this.user).subscribe(() => {
      $this.loadingService.toggle("");
      $this.router.navigate(["backoffice/users"]);
    });
  }
}