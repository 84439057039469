import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { SEFModel } from '../../shared/models/SEFModel.model';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { SEFDocumentsListModel } from 'src/app/shared/models/SEFDocumentsListModel.model';
import { SEFDocumentModel } from 'src/app/shared/models/SEFDocumentModel.model';

@Injectable({
  providedIn: 'root'
})
export class SefConnectionService {

  private userToken:string;
  
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
   }

  public GetSEFDocumentsByBookingId(argBookingId): Observable<Array<SEFModel>> {

    const $this = this;

    return $this.http.get<Array<SEFModel>>(environment.apiUrl + 'api/v1/SEFIntegration/GetSEFDocumentsByBookingId/' + argBookingId).pipe();

  }

  public SaveInDataBase(argListOfDataToSend: Array<SEFModel>): Observable<Array<SEFModel>> {

    const $this = this;

    return $this.http.post<Array<SEFModel>>(environment.apiUrl + 'api/v1/SEFIntegration/SaveInDataBase', argListOfDataToSend).pipe();

  }

  public SendDataToSEF(argListOfDataToSend: Array<SEFModel>): Observable<Array<SEFModel>> {

    const $this = this;

    return $this.http.post<Array<SEFModel>>(environment.apiUrl + 'api/v1/SEFIntegration', argListOfDataToSend).pipe();

  }

  public RemoveSEFDocumentsBySEFDocumentId(argSEFDocumentId: number): Observable<boolean> {

    const $this = this;

    return $this.http.delete<boolean>(environment.apiUrl + 'api/v1/SEFIntegration/' + argSEFDocumentId).pipe();

  }

  public GetAllColumnNames(): Observable<string[]>{
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }

    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/SEFDocument/GetAllColumnNames', header).pipe();
  }

  public GetAllSEFDocuments(filterParameters: FiltersModel): Observable<SEFDocumentsListModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }

    return $this.http.post<SEFDocumentsListModel>(environment.apiUrl + 'api/v1/SEFDocument/GetAllSEFDocuments', filterParameters, header).pipe();
  }

  public DeleteListOfSEFDocuments(listOfDocuments: any[]):Observable<any> {
    const $this = this;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
      .set('Authorization',  'Bearer ' + $this.userToken),
      body: listOfDocuments,
    };
    return $this.http.delete<any>(environment.apiUrl + 'api/v1/SEFDocument', options).pipe();
  }

  public GetSEFDocumentById(id:number): Observable<SEFDocumentModel>{
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  'Bearer ' + $this.userToken)
    }

    return $this.http.get<SEFDocumentModel>(environment.apiUrl + 'api/v1/SEFDocument/' + id, header).pipe();
  }
}
