import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { PMSSettingsListModel } from 'src/app/shared/models/pmssettings-list-model';
import { PublicPMSSettingModel } from 'src/app/shared/models/public-pmssetting-model';
import { UtilitiesService } from '../utilities-service/utilities.service';

@Injectable({
  providedIn: 'root'
})
export class PmsSettingService {

  private userToken: string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService,
    private utilitiesService: UtilitiesService) {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
  }
  public GetAllColumnNames(): Observable<string[]> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }
    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/PMSSetting/GetAllColumnNames', header).pipe();
  }

  public GetPMSSettingById(id: number): Observable<PublicPMSSettingModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }
    return $this.http.get<PublicPMSSettingModel>(environment.apiUrl + 'api/v1/PMSSetting/' + id, header).pipe(map(returnPmsSetting => {
      if (returnPmsSetting.pmsSettings.delayMilliseconds > 0) {
        returnPmsSetting.pmsSettings.delayMilliseconds = returnPmsSetting.pmsSettings.delayMilliseconds / 1000;
      }
      return returnPmsSetting;
    }));
  }

  public GetAllPMSSettings(filterParameters: FiltersModel): Observable<PMSSettingsListModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<PMSSettingsListModel>(environment.apiUrl + 'api/v1/PMSSetting/GetAllPMSSettings', filterParameters, header).pipe();
  }

  public SaveOrUpdatePMSSetting(model: PublicPMSSettingModel): Observable<PublicPMSSettingModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    model.pmsSettings.delayMilliseconds = model.pmsSettings.delayMilliseconds * 1000;

    model.pmsSettings = $this.utilitiesService.convertKeysToFirstLetterUppercase(model.pmsSettings);

    const convertedData = $this.utilitiesService.convertKeysToFirstLetterUppercase(model);

    const postData = convertedData;

    return $this.http.post<PublicPMSSettingModel>(environment.apiUrl + 'api/v1/PMSSetting', postData, header).pipe();
  }

  public DeleteListOfPMSSetting(listOfDocuments: any[]): Observable<any> {
    const $this = this;
    let listToDelete = JSON.stringify(listOfDocuments);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }).set('Authorization', 'Bearer ' + $this.userToken),
      body: listOfDocuments,
    };
    return $this.http.delete<any>(environment.apiUrl + 'api/v1/PMSSetting', options).pipe();
  }
}
