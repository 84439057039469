<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <div style="padding: 10px">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <mat-accordion>
              <mat-expansion-panel
                *ngFor="let kiosk of differentKiosks"
                [expanded]="differentKiosks.length === 1"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon aria-hidden="false" aria-label="Niveis"
                      >stacked_bar_chart</mat-icon
                    >
                    <span style="margin-left: 20px"
                      >Níveis de Númerário no {{ kiosk.name }}</span
                    >
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div
                  *ngIf="kiosk.tubeLevels.length > 0"
                  class="container container-levels"
                >
                  <mat-toolbar color="primary" style="margin-bottom: 10px">
                    <span
                      >Níveis nos Recicladores
                      <small
                        >(Última atualização:
                        {{ kiosk.tubeLevels[0].snapTime }})</small
                      ></span
                    >
                  </mat-toolbar>
                  <ng-container
                    *ngFor="
                      let coinForChange of kiosk.tubeLevels;
                      let i = index
                    "
                  >
                    <div *ngIf="i % 5 === 0" class="row justify-content-center">
                      <ng-container
                        *ngFor="
                          let innerItem of kiosk.tubeLevels.slice(i, i + 5)
                        "
                      >
                        <div class="col-xl-2 col-md-2 col-sm-12">
                          <p class="header-value">
                            {{ getTubeTypeLabel(innerItem.tubeType) }}
                          </p>
                          <p>{{ innerItem.quantity }}</p>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>

                <div
                  *ngIf="kiosk.cashboxLevels.length > 0"
                  class="container container-levels"
                >
                  <mat-toolbar color="primary" style="margin-bottom: 10px">
                    <span
                      >Níveis nos Cofres
                      <small
                        >(Última atualização:
                        {{ kiosk.cashboxLevels[0].snapTime }})</small
                      ></span
                    >
                  </mat-toolbar>
                  <ng-container
                    *ngFor="
                      let coinForChange of kiosk.cashboxLevels;
                      let i = index
                    "
                  >
                    <div *ngIf="i % 5 === 0" class="row justify-content-center">
                      <ng-container
                        *ngFor="
                          let innerItem of kiosk.cashboxLevels.slice(i, i + 5)
                        "
                      >
                        <div class="col-xl-2 col-md-2 col-sm-12">
                          <p class="header-value">
                            {{ getTubeTypeLabel(innerItem.tubeType) }}
                          </p>
                          <p>{{ innerItem.quantity }}</p>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
