<div class="qm-card-container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="qm-card">
    <div class="container container-form">
      <form>
        <div class="row">
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Local de Alojamento</mat-label>
              <mat-select [(ngModel)]="sefDocument.localId" (selectionChange)="checkFields()" name="localId">
                <mat-option *ngFor="let local of locals" [value]="local.id">
                  {{ local.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Nº de Reserva</mat-label>
              <input matInput [(ngModel)]="currentSEFDocument.bookingNumber" readonly name="bookingNumber">
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Nº Reserva do Canal</mat-label>
              <input matInput [(ngModel)]="currentSEFDocument.channelBookingNumber" readonly
                name="channelBookingNumber">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width" appearance="fill">
              <mat-label>Data de Check-in</mat-label>
              <input matInput [matDatepicker]="checkinpicker" [(ngModel)]="sefDocument.checkin" (change)="checkFields()"
                name="checkin">
              <mat-datepicker-toggle matSuffix [for]="checkinpicker"></mat-datepicker-toggle>
              <mat-datepicker #checkinpicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width" appearance="fill">
              <mat-label>Data de Check-out</mat-label>
              <input matInput [matDatepicker]="checkoutpicker" [(ngModel)]="sefDocument.checkout"
                (change)="checkFields()" name="checkout">
              <mat-datepicker-toggle matSuffix [for]="checkoutpicker"></mat-datepicker-toggle>
              <mat-datepicker #checkoutpicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Estado</mat-label>
              <input matInput [(ngModel)]="currentSEFDocument.state.name" readonly name="state">
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Nº de Tentativas de Submissão</mat-label>
              <input matInput [(ngModel)]="currentSEFDocument.numberOfAttempts" readonly name="numberOfAttempts">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Nome do Hóspede</mat-label>
              <input matInput [(ngModel)]="sefDocument.guestName" (keyup)="checkFields()" name="guestName">
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Primeiro Nome do Hóspede</mat-label>
              <input matInput [(ngModel)]="sefDocument.guestFirstName" (keyup)="checkFields()" name="guestFirstName">
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Apelido Nome do Hóspede</mat-label>
              <input matInput [(ngModel)]="sefDocument.guestLastName" (keyup)="checkFields()" name="guestLastName">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width" appearance="fill">
              <mat-label>Data de Nascimento</mat-label>
              <input matInput [matDatepicker]="dateOfBirthpicker" [(ngModel)]="sefDocument.dateOfBirth"
                (change)="checkFields()" name="dateOfBirth">
              <mat-datepicker-toggle matSuffix [for]="dateOfBirthpicker"></mat-datepicker-toggle>
              <mat-datepicker #dateOfBirthpicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Local de Nascimento</mat-label>
              <input matInput [(ngModel)]="sefDocument.birthplace" (keyup)="checkFields()" name="birthplace">
            </mat-form-field>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width" appearance="fill">
              <mat-label>Nacionalidade</mat-label>
              <input type="text" matInput [formControl]="nationality" (optionSelected)="checkFields()"
                [(ngModel)]="sefDocument.nationality" name="nationality" [matAutocomplete]="nationalityAuto">
              <mat-autocomplete #nationalityAuto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                <mat-option *ngFor="let country of filterednationality | async" [value]="country.icaoCode">
                  {{country.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Nº do Documento de Identificação</mat-label>
              <input matInput [(ngModel)]="sefDocument.documentIdentification" (keyup)="checkFields()"
                name="documentIdentification">
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Tipo de Documento</mat-label>
              <mat-select [(ngModel)]="sefDocument.documentType" (selectionChange)="checkFields()" name="documentType">
                <mat-option *ngFor="let documentType of documentTypes" [value]="documentType.sefCode">
                  {{ documentType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width" appearance="fill">
              <mat-label>País de Emissão do Documento</mat-label>
              <input type="text" matInput [formControl]="issuingCountry" (optionSelected)="checkFields()"
                [(ngModel)]="sefDocument.issuingCountry" name="issuingCountry" [matAutocomplete]="issuingCountryAuto">
              <mat-autocomplete #issuingCountryAuto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                <mat-option *ngFor="let country of filteredissuingCountry | async" [value]="country.icaoCode">
                  {{country.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Data de Expiração Documento Identificação</mat-label>
              <input matInput [matDatepicker]="idExpireDatepicker" [(ngModel)]="sefDocument.idExpireDate"
                (change)="checkFields()" name="idExpireDate">
              <mat-datepicker-toggle matSuffix [for]="idExpireDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #idExpireDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width" appearance="fill">
              <mat-label>País de Residência</mat-label>
              <input type="text" matInput (change)="checkFields()" [formControl]="countryOfResidence"
                [(ngModel)]="sefDocument.countryOfResidence" name="countryOfResidence"
                [matAutocomplete]="countryOfResidenceAuto">
              <mat-autocomplete #countryOfResidenceAuto="matAutocomplete" (optionSelected)="checkFields()"
                [displayWith]="displayFn.bind(this)">
                <mat-option *ngFor="let country of filteredcountryOfResidence | async" [value]="country.icaoCode">
                  {{country.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Local de Residência</mat-label>
              <input matInput [(ngModel)]="sefDocument.residenceCity" (keyup)="checkFields()" name="residenceCity">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-3 col-md-3 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Nº de Contacto</mat-label>
              <input matInput [(ngModel)]="sefDocument.mobileNumber" (keyup)="checkFields()" name="mobileNumber">
            </mat-form-field>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12">
            <mat-form-field class="input-full-width">
              <mat-label>Email</mat-label>
              <input matInput [(ngModel)]="sefDocument.email" (keyup)="checkFields()" name="email">
            </mat-form-field>
          </div>
          <div class="col-xl-3 col-md-3 col-sm-12">
            <div class="input-full-width mat-form-field-flex slide-container">
              <mat-slide-toggle [(ngModel)]="sefDocument.isAdult" name="isAdult">Adulto?
              </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <mat-form-field class="input-full-width" appearance="fill">
              <mat-label>Erro de Emissão</mat-label>
              <textarea matInput [(ngModel)]="currentSEFDocument.errorMessage" readonly name="errorMessage"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="document-container input-full-width">
              <input style="display: none;" accept=".png,.jpg" [(ngModel)]="documentInput" name="documentInput"
                type="file" (change)="onFileChanged($event)" #fileInput>
              <div class="document-img-container">
                <img *ngIf="hasFile" class="document-container-img" [src]="selectedImagePreview">
                <mat-icon *ngIf="!hasFile" class="document-container-icon" aria-hidden="false">insert_drive_file
                </mat-icon>
              </div>
              <div class="document-btn-container">
                <div class="document-btn-container-col">
                  <div class="document-btn-container-wrapper">
                    <button class="form-button button-form-ptt" (click)="fileInput.click()" mat-raised-button
                      color="btn-custom">
                      <mat-icon aria-hidden="false">file_upload</mat-icon>
                      UPLOAD
                    </button>
                  </div>
                </div>
                <div class="document-btn-container-col">
                  <div class="document-btn-container-wrapper">
                    <button class="form-button button-form-ptt" (click)="clear()" mat-raised-button color="btn-custom">
                      <mat-icon aria-hidden="false">
                        delete_forever
                      </mat-icon>
                      REMOVER
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row row-footer">
      <div class="container">
        <div class="row">
          <div [ngClass]="{'col-xl-4 col-md-4 col-sm-12': hasSEF, 'col-xl-6 col-md-6 col-sm-12': !hasSEF}">
            <button class="form-button button-form-ptt" (click)="goBack()" mat-raised-button color="accent">
              <mat-icon aria-hidden="false">arrow_back</mat-icon>
              VOLTAR
            </button>
          </div>
          <div *ngIf="hasSEF" class="col-xl-4 col-md-4 col-sm-12 align-center">
            <button class="form-button button-form-ptt" (click)="saveAndEmitToSEFData()" [disabled]="!enableSave"
              mat-raised-button color="accent">
              <mat-icon aria-hidden="false">insert_drive_file</mat-icon>
              GRAVAR & EMITIR PARA O SEF
            </button>
          </div>
          <div [ngClass]="{'col-xl-4 col-md-4 col-sm-12 align-right': hasSEF, 'col-xl-6 col-md-6 col-sm-12 align-right': !hasSEF}">
            <button class="form-button button-form-ptt" (click)="saveData()" [disabled]="!enableSave" mat-raised-button
              color="accent">
              <mat-icon aria-hidden="false">save</mat-icon>
              GRAVAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
