import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { KioskMoneyMaintenanceTransactionListModel } from 'src/app/shared/models/kiosk-money-maintenance-transaction-list-model';

@Injectable({
  providedIn: 'root'
})
export class KioskMoneyMaintenanceTransactionService {

  private userToken: string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
  }

  public GetAllColumnNames(): Observable<string[]> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/KioskMoneyMaintenanceTransaction/GetAllColumnNames', header).pipe();
  }

  public GetAllKioskMoneyMaintenanceTransactions(filterParameters: FiltersModel): Observable<KioskMoneyMaintenanceTransactionListModel> {
    const $this = this;

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<KioskMoneyMaintenanceTransactionListModel>(environment.apiUrl + 'api/v1/KioskMoneyMaintenanceTransaction/GetKioskMoneyMaintenanceTransactions', filterParameters, header).pipe();
  }
}
