import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { LanguageModel } from 'src/app/shared/models/LanguageModel.model';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { CreateLanguageModel } from 'src/app/shared/models/CreateLanguageModel.model';
import { LanguageListModel } from 'src/app/shared/models/LanguageListModel.model';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private userToken: string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
  }

  public GetLanguages(): Observable<LanguageModel[]> {

    const $this = this;

    return $this.http.get<LanguageModel[]>(environment.apiUrl + 'api/v1/Language/').pipe();

  }

  public GetAllColumnNames(): Observable<string[]> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }
    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/Language/GetAllColumnNames', header).pipe();
  }

  public GetLanguageById(id: number): Observable<LanguageModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }
    return $this.http.get<LanguageModel>(environment.apiUrl + 'api/v1/Language/' + id, header).pipe();
  }

  public GetAllLanguages(filterParameters: FiltersModel): Observable<LanguageListModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<LanguageListModel>(environment.apiUrl + 'api/v1/Language/GetAllLanguages', filterParameters, header).pipe();
  }

  public SaveLanguage(dataToSave: CreateLanguageModel): Observable<any> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<any>(environment.apiUrl + 'api/v1/Language', dataToSave, header).pipe();
  }

  public DeleteListOfLanguage(listOfDocuments: any[]): Observable<any> {
    const $this = this;
    let listToDelete = JSON.stringify(listOfDocuments);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }).set('Authorization', 'Bearer ' + $this.userToken),
      body: listOfDocuments,
    };
    return $this.http.delete<any>(environment.apiUrl + 'api/v1/Language', options).pipe();
  }
}
