import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ConfigService } from 'src/app/services/config/config.service';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { UtilitiesService } from 'src/app/services/utilities-service/utilities.service';
import { ConfigModel } from 'src/app/shared/models/ConfigModel.model';
import * as _ from "underscore";

@Component({
  selector: 'app-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.scss']
})
export class ConfigFormComponent implements AfterViewInit {

  constructor(private configService: ConfigService, private loadingService: LoadingService,
    private router: Router, private utilitiesService: UtilitiesService) {
  }

  config: ConfigModel = {
    id: 0,
    isActive: false,
    smartMoneySystemPassword: "",
    smartMoneySystemUrl: "",
    smartMoneySystemUser: ""
  };

  ngAfterViewInit(): void {
    const $this = this;
    $this.loadingService.toggle("");
    $this.configService.GetConfigActive().subscribe(resp => {
      $this.config = resp;
      $this.loadingService.toggle("");
    });
  }

  saveData() {
    const $this = this;
    $this.loadingService.toggle("");

    $this.configService.SaveConfig($this.config).subscribe((resp) => {
      $this.config = resp;
      $this.loadingService.toggle("");
    });
  }
}