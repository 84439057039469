export enum MaintenanceOperationType {
    None,
    Filling,
    EmptyingRecyclers,
    CashMoved,
    EmptyingCashboxs,
    AtmOpenPeriod,
    AtmClosePeriod,
    CashOpenPeriod,
    CashClosePeriod
}


export const MaintenanceOperationTypeLabel = new Map<MaintenanceOperationType, string>([
    [MaintenanceOperationType.None, 'Erro no Encoding do Cartão'],
    [MaintenanceOperationType.Filling, 'Carregamento'],
    [MaintenanceOperationType.EmptyingRecyclers, 'Esvaziamento dos recicladores'],
    [MaintenanceOperationType.CashMoved, 'Movimento de dinheiro'],
    [MaintenanceOperationType.EmptyingCashboxs, 'Esvaziamento dos depositos'],
    [MaintenanceOperationType.AtmOpenPeriod, 'Abertura de Período POS'],
    [MaintenanceOperationType.AtmClosePeriod, 'Fecho de Período POS'],
    [MaintenanceOperationType.CashOpenPeriod, 'Abertura de Período Noteiro / Moedeiro'],
    [MaintenanceOperationType.CashClosePeriod, 'Fecho de Período Noteiro / Moedeiro']
  ]);