import { Component, OnInit } from '@angular/core';
import { ExitCheckinService } from '../services/exitCheckin/exit-checkin.service';
import { Router } from '@angular/router';
import { SefConnectionService } from '../services/sefConnection/sef-connection.service';
import { ColibriConnectionService } from '../services/colibriConnection/colibri-connection.service';
import { LoadingService } from '../services/loading/loading.service';
import { SEFModel } from '../shared/models/SEFModel.model';
import * as _ from 'underscore';
import { BookingColibriModel } from '../shared/models/BookingColibriModel.model';
import { GuestCountColibriModel } from '../shared/models/GuestCountColibriModel.model';
import { StoreDataService } from '../services/storeData/store-data.service';
import { UtilitiesService } from '../services/utilities-service/utilities.service';

@Component({
  selector: 'app-guests-number',
  templateUrl: './guests-number.component.html',
  styleUrls: ['./guests-number.component.css']
})
export class GuestsNumberComponent implements OnInit {

  currentBookingData: BookingColibriModel;
  adultCounter: number = 0;
  kidCounter: number = 0;
  maxAdult: number = 0;
  maxKid: number = 0;
  nyAddAdult: boolean;
  nyAddKid: boolean;
  nyRemoveAdult: boolean;
  nyRemoveKid: boolean;
  displayChangeBookingButton: boolean = false;
  listOfGuests: Array<SEFModel> = [];
  showPayButton: boolean = true;
  showNextButton: boolean = true;

  constructor(private exitCheckinService: ExitCheckinService, private router: Router,
    private sefConnectionService: SefConnectionService, private loadingService: LoadingService,
    private colibriConnectionService: ColibriConnectionService, private storeDataService: StoreDataService, private utilitiesService: UtilitiesService) { }

  ngOnInit(): void {

    const $this = this;
    $this.loadingService.toggle("");
    //let currentBooking = localStorage.getItem('cb');

    let currentBooking = $this.storeDataService.cb;

    if (!$this.utilitiesService.isNullOrEmpty(currentBooking)) {

      $this.currentBookingData = JSON.parse(currentBooking);
      $this.showPayButton = parseFloat($this.currentBookingData.totalToPayDue) > 0;

      $this.maxAdult = parseInt($this.currentBookingData.maxAdult);
      $this.maxKid = parseInt($this.currentBookingData.maxChildren);

      let guestCounts = $this.currentBookingData.guestCounts;

      for (let index = 0; index < guestCounts.length; index++) {
        const element = guestCounts[index];

        if (element.isAdult) {
          $this.adultCounter += element.count;
        }
        else {
          $this.kidCounter += element.count;
        }
      }

      $this.toogleButtons();

      $this.sefConnectionService.GetSEFDocumentsByBookingId($this.currentBookingData.yKioskBookingId).subscribe(resp => {

        $this.listOfGuests = resp;

        $this.showNextButton = $this.listOfGuests.length != $this.adultCounter + $this.kidCounter;

        if ($this.listOfGuests.length > 0 && _.where($this.listOfGuests, { yKioskStateId: 1 }).length > 0) {

        } else if ($this.listOfGuests.length > 0 && $this.listOfGuests[0].documentImage != null) {

          $this.displayChangeBookingButton = true;

          $this.storeDataService.guests = JSON.stringify($this.listOfGuests);

        } else {
          $this.storeDataService.currentGuest = JSON.stringify($this.listOfGuests[0]);
          $this.storeDataService.guests = JSON.stringify($this.listOfGuests);
        }

        $this.loadingService.toggle("");
      });
    }
  }

  toogleButtons() {
    const $this = this;
    $this.nyRemoveAdult = $this.adultCounter > 1;
    $this.nyRemoveKid = $this.kidCounter > 0;
    $this.nyAddAdult = $this.adultCounter < $this.maxAdult;
    $this.nyAddKid = $this.kidCounter < $this.maxKid;
  }

  addAdult() {
    const $this = this;
    $this.adultCounter++;
    $this.toogleButtons();
  }

  removeAdult() {
    const $this = this;
    $this.adultCounter--;
    $this.toogleButtons();
  }

  addKid() {
    const $this = this;
    $this.kidCounter++;
    $this.toogleButtons();
  }

  removeKid() {
    const $this = this;
    $this.kidCounter--;
    $this.toogleButtons();
  }

  nextPage() {
    const $this = this;

    //let currentGuestString = localStorage.getIte

    let currentGuestString = $this.storeDataService.currentGuest;

    if ($this.utilitiesService.isNullOrEmpty(currentGuestString)) {
      let newGuest: SEFModel = {
        guestName: $this.currentBookingData.ownerGuest,
        guestFirstName: "",
        guestLastName: "",
        nationality: "",
        dateOfBirth: "",
        birthplace: "",
        documentIdentification: "",
        documentType: "",
        issuingCountry: "",
        checkin: $this.currentBookingData.checkin,
        checkout: $this.currentBookingData.checkout,
        countryOfResidence: "",
        residenceCity: "",
        mobileNumber: "",
        email: "",
        documentImage: "",
        yKioskBookingId: 0,
        yKioskSEFDocumentId: 0,
        yKioskStateId: 0,
        isAdult: 0,
        localId: 0,
        isProductionMode: 0,
        colibriGuestId: 0,
        guestGender: null,
        idExpireDate: null
      };

      //localStorage.setItem('currentGuest', JSON.stringify(newGuest));

      $this.storeDataService.currentGuest = JSON.stringify(newGuest);
    }

    $this.updateGuestsNumber('/sef-form');
  }

  editBooking() {
    const $this = this;
    $this.updateGuestsNumber('/edit-booking');
  }

  updateGuestsNumber(navigationEndPoint: string) {
    const $this = this;
    $this.loadingService.toggle("");
    if (_.findWhere($this.currentBookingData.guestCounts, { isAdult: true }) != null) {
      _.findWhere($this.currentBookingData.guestCounts, { isAdult: true }).count = $this.adultCounter;
    }

    if (_.findWhere($this.currentBookingData.guestCounts, { isAdult: false }) == null) {
      let newKidCounter: GuestCountColibriModel = {
        count: $this.kidCounter,
        isAdult: false
      };
      $this.currentBookingData.guestCounts.push(newKidCounter);
    } else {
      _.findWhere($this.currentBookingData.guestCounts, { isAdult: false }).count = $this.kidCounter;
    }

    $this.currentBookingData.numberOfGuests = ($this.adultCounter + $this.kidCounter).toString();

    $this.colibriConnectionService.TotalToPay($this.currentBookingData.bookingNumber, $this.adultCounter.toString(), $this.kidCounter.toString()).subscribe(total => {

      if (total.totalToPayDue != null) {
        $this.currentBookingData.totalToPayDue = total.totalToPayDue;
      }

      $this.colibriConnectionService.UpdateBookingData($this.currentBookingData).subscribe(resp => {
        //localStorage.setItem('cb', JSON.stringify(resp));
        $this.storeDataService.cb = JSON.stringify(resp);
        $this.loadingService.toggle("");
        $this.router.navigate([navigationEndPoint]);
      })
    });
  }

  prevPage() {
    const $this = this;
    /*localStorage.removeItem('cb');
    localStorage.removeItem('guests');
    localStorage.removeItem('currentGuest');*/
    $this.storeDataService.cb = '';
    $this.storeDataService.guests = '';
    $this.storeDataService.currentGuest = '';
    $this.router.navigate(['/search-booking']);
  }

  finishCheckin() {
    const $this = this;
    $this.router.navigate(['/end']);
  }

  pay() {
    const $this = this;
    $this.router.navigate(['payment']);
  }

  exit() {
    const $this = this;
    $this.exitCheckinService.openPopup();
  }

}
