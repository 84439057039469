export enum TransactionState {
    None,
    Done,
    Canceled,
    Error,
    Returned,
    TotalAmountNotRefundedOnCancellation,
    TheFullAmountWasNotReturned
}


export const TransactionStateLabel = new Map<TransactionState, string>([
    [TransactionState.None, 'Nenhum'],
    [TransactionState.Done, 'Concluído'],
    [TransactionState.Canceled, 'Cancelado'],
    [TransactionState.Error, 'Erro'],
    [TransactionState.Returned, 'Devolvido'],
    [TransactionState.TotalAmountNotRefundedOnCancellation, 'Não foi devolvido o montante total no cancelamento'],
    [TransactionState.TheFullAmountWasNotReturned, 'Não foi devolvido o montante total']
  ]);