export enum MoneyOperationType {
    None,
    Payment,
    Refund
}

export const MoneyOperationTypeLabel = new Map<MoneyOperationType, string>([
    [MoneyOperationType.None, 'Erro no Encoding do Cartão'],
    [MoneyOperationType.Payment, 'Pagamento'],
    [MoneyOperationType.Refund, 'Devolução']
  ]);