import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppMessageModel } from 'src/app/shared/models/AppMessageModel.model';
import { AppMessagesListModel } from 'src/app/shared/models/AppMessagesListModel.model';
import { CreateAppMessageModel } from 'src/app/shared/models/CreateAppMessageModel.model';
import { FiltersModel } from 'src/app/shared/models/FiltersModel.model';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AppMessageService {

  private userToken: string;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    const $this = this;
    $this.userToken = $this.authenticationService.currentUserValue.Token;
  }
  public GetAllColumnNames(): Observable<string[]> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }
    return $this.http.get<string[]>(environment.apiUrl + 'api/v1/AppMessage/GetAllColumnNames', header).pipe();
  }

  public GetAllAppMessages(filterParameters: FiltersModel): Observable<AppMessagesListModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<AppMessagesListModel>(environment.apiUrl + 'api/v1/AppMessage/GetAllAppMessages', filterParameters, header).pipe();
  }

  public GetAppMessageToSendByIdOnline(id: number): Observable<AppMessageModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }
    return $this.http.get<AppMessageModel>(environment.apiUrl + 'api/v1/AppMessage/GetAppMessageToSendByIdOnline/' + id, header).pipe();
  }

  public SaveAppMessageOnline(dataToSave: CreateAppMessageModel): Observable<AppMessageModel> {
    const $this = this;
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + $this.userToken)
    }

    return $this.http.post<AppMessageModel>(environment.apiUrl + 'api/v1/AppMessage/SaveAppMessageOnline', dataToSave, header).pipe();
  }

  public DeleteListOfAppMessage(listOfDocuments: any[]): Observable<any> {
    const $this = this;
    let listToDelete = JSON.stringify(listOfDocuments);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }).set('Authorization', 'Bearer ' + $this.userToken),
      body: listOfDocuments,
    };
    return $this.http.delete<any>(environment.apiUrl + 'api/v1/AppMessage', options).pipe();
  }
}
