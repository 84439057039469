import { Component, OnInit } from '@angular/core';
import { BookingColibriModel } from '../shared/models/BookingColibriModel.model';
import { Router } from '@angular/router';
import { StoreDataService } from '../services/storeData/store-data.service';
import { UtilitiesService } from '../services/utilities-service/utilities.service';

@Component({
  selector: 'app-next-guest',
  templateUrl: './next-guest.component.html',
  styleUrls: ['./next-guest.component.css']
})
export class NextGuestComponent implements OnInit {

  constructor(private router: Router, private storeDataService: StoreDataService, private utilitiesService: UtilitiesService) { }
  
  currentBookingData: BookingColibriModel;

  ngOnInit(): void {

    const $this = this;
    //let currentBooking = localStorage.getItem('cb');
    let currentBooking = $this.storeDataService.cb;

    if (!$this.utilitiesService.isNullOrEmpty(currentBooking)) {
      $this.currentBookingData = JSON.parse(currentBooking);
    }

  }

  nextPage(){
    const $this = this;
    let guestOwner = {
      guestName: $this.currentBookingData.ownerGuest,
      guestFirstName: "",
      guestLastName: "",
      nationality: "",
      dateOfBirth: "",
      birthplace: "",
      documentIdentification: "",
      documentType: "",
      issuingCountry: "",
      checkin: $this.currentBookingData.checkin,
      checkout: $this.currentBookingData.checkout,
      countryOfResidence: "",
      residenceCity: "",
      mobileNumber: "",
      email: "",
      documentImage: "",
      yKioskBookingId: 0,
      yKioskSEFDocumentId: 0,
      yKioskStateId: 0,
      isAdult: 0,
      localId: 0,
      isProductionMode: 0,
    };
    //localStorage.setItem('currentGuest', JSON.stringify(guestOwner));
    if($this.storeDataService.currentGuest == null || $this.storeDataService.currentGuest == undefined || JSON.parse($this.storeDataService.currentGuest).documentImage != null){
      $this.storeDataService.currentGuest = JSON.stringify(guestOwner);
    }
  
    $this.router.navigate(['sef-form']);
  }
}
